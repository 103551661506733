import './App.css';

function App() {
  return (
    <div className="App">
      <img alt="girls kissing" src="https://media1.tenor.com/m/Wgk_0iJebWUAAAAC/anime-girl-kiss-akademi-online.gif"></img>
      <p>probe</p>
    </div>
  );
}

export default App;
